.section {
  margin-bottom: 50px;
  position: relative;
  z-index: 1;

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}

.section__heading {
  font-size: 80%;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 10px;
  padding-top: 3px;
  display: flex;
}

.section__close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: black;
  margin-left: 10px;
}

.section__edit {
  display: block;
  position: relative;
  color: black;

  &,
  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }

  &:focus,
  &:hover {
    &:before {
      position: absolute;
      left: -15px;
      right: -15px;
      bottom: -10px;
      top: -3px;
      border: 1px solid #00bbff;
      background-color: rgba(#00bbff, 0.1);
      content: '';
      border-radius: 3px;
      z-index: -1;
    }
  }
}

.section__requirements {
  margin-top: 15px;

  .ant-collapse {
    + .ant-collapse {
      margin-top: -1px;
    }
  }
}

.section--active {
  &:before {
    position: absolute;
    left: -15px;
    right: -15px;
    bottom: -15px;
    top: -15px;
    border: 1px solid rgba(black, 0.2);
    background-color: rgba(black, 0.01);
    content: '';
    border-radius: 3px;
    z-index: -1;
  }
}

.date-tooltip {
  // stylelint-disable-next-line
  max-width: max-content !important;
  font-size: 12px;
  color: #444;

  .tooltip__inner {
    padding: 0;
    overflow: hidden;
  }
}

.date-tooltip__table thead {
  color: #444;
  background: #fafafa;
}

.date-tooltip__table {
  td {
    padding: 3px 10px;
    vertical-align: top;
    color: #444;
  }

  th {
    padding: 6px 10px;
    color: #444;
  }

  tr:first-child td {
    padding-top: 8px;
  }

  tr:last-child td {
    padding-bottom: 8px;
  }
}

.date-tooltip__table tfoot {
  tr:first-child {
    /* stylelint-disable-next-line */
    td {
      border-top: 1px solid #fafafa;
    }
  }
}

.header {
  top: 0;
  left: 0;
  position: fixed;
  z-index: var(--header-zindex);
  width: 100%;
  height: var(--header-height);
  line-height: 1;
  margin-bottom: 20px;
  background: white;
  padding: 0 24px 0 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  .ant-btn {
    margin-left: 10px;
  }

  .ant-menu {
    line-height: 32px;
    margin-left: 14px;

    .ant-menu-overflow-item {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .ant-menu-submenu:after,
  .ant-menu-item:after {
    left: 10px !important;
    right: 10px !important;
  }

  .ant-menu-item-selected.selected {
    border-bottom: 2px solid #1890ff;
    /* margin-bottom: -2px; */
  }
}

.header__logo {
  font-weight: lighter;
  margin-left: 24px;
  margin-right: 12px;
  margin-bottom: 0;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  display: flex;
  align-items: center;
}

.header__logo-text {
  border-left: 1px solid black;
  margin-left: 11px;
  padding-left: 10px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 1;
  margin-bottom: -1px;
}

.header__inner {
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.header__title {
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header__back {
  color: black !important;
  font-size: 20px;
  margin-right: 10px;
}

.build {
  --tags-width: 200px;
}
.build__pdf {
  overflow-y: scroll;
  height: calc(100vh - var(--header-height));
  width: 100vw;
  margin: 0 -24px;
  background-color: #ccc;
  border: 0;

  &::-webkit-scrollbar-thumb {
    border: 3px solid #ccc;
  }
}

.build__title {
  min-width: 0;
  @media (min-width: 1024px) {
    width: calc(50vw - var(--tags-width) / 2);
  }
  padding-left: 20px;
}

.build__title-inner {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap;
}

.build__tags {
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0;
    width: var(--tags-width);
  }
  text-align: center;
  flex-shrink: 0;

  .ant-tag {
    margin: 0 5px;
  }
}

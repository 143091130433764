:root {
  --header-height: 72px;
  --header-zindex: 100;
  --right-sidebar-zindex: 99;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--header-height) + 20px);
}

body {
  /* overflow-y: scroll; */
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  padding: 10px 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;
  background-clip: content-box;
}

.tree {
  a {
    color: black;
  }
}

.tree-search-value {
  background: #fffbe7;
  color: red;
}

.tree-link {
  &.active {
    color: black;
    &:before {
      position: absolute;
      content: '';

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 2px;
      background: rgba(#0bf, 0.1);
    }
  }
}

.monospace {
  font-family: 'Roboto Mono', monospace;
  white-space: pre-wrap;
}

.remove-field-button {
  background: transparent;
  border: 0;
  height: 32px;
  width: 32px;
  margin-bottom: 24px;
  cursor: pointer;
}

.table {
  padding: 20px 24px;
  margin: 0 0 0 -24px;
  max-width: 100vw;
  width: 100vw;
  overflow-y: scroll;
  height: calc(100vh - var(--header-height));

  th {
    background: #fff;
    white-space: nowrap;
  }

  .ant-table-row-expand-icon {
    border: 1px solid black;
    color: black;
    transform: scale(0.82);
    margin-top: 2px;
    &:before,
    &:after {
      transition: none;
    }
  }

  .ant-table-row-expand-icon-spaced {
    width: 0;
  }
}

.text-overflow {
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
}

.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

@each $i in (0, 1, 2, 3) {
  .ml-#{$i} {
    margin-left: $i * 10px !important;
  }
  .mr-#{$i} {
    margin-right: $i * 10px !important;
  }
  .mt-#{$i} {
    margin-top: $i * 10px !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * 10px !important;
  }
  .pl-#{$i} {
    padding-left: $i * 10px !important;
  }
  .pr-#{$i} {
    padding-right: $i * 10px !important;
  }
  .pt-#{$i} {
    padding-top: $i * 10px !important;
  }
  .pb-#{$i} {
    padding-bottom: $i * 10px !important;
  }
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.form-footer__placeholder {
  height: 52px;

  .ant-modal-body & {
    height: 0;
  }
}
.form-footer {
  position: fixed;
  border-top: 1px solid #f0f0f0;
  padding: 10px 20px;
  left: var(--col-width);
  right: 0px;
  height: 53px;
  background: white;
  z-index: 100;
  bottom: 0;
  overflow-y: scroll;

  .ant-modal-body & {
    position: static;
    margin: 0 -20px;
    padding: 20px 20px 0;

    .ant-btn-dangerous {
      display: block;
      position: absolute;
      top: 65px;
      right: 24px;
    }

    .ant-btn-primary {
      order: 2;
      margin-left: auto;

      + .ant-btn {
        order: 1;
        margin-left: 0;
      }
    }
  }

  .ant-btn + .ant-btn {
    margin-left: 10px;
  }

  .ant-btn-primary {
    width: 200px;
  }

  .layout__right-sidebar & {
    left: 0;
    position: sticky;
    bottom: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}

.form-footer__inner {
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

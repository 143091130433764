.layout {
  padding: var(--header-height) 24px 0;
  background: #fff;

  --col-width: 250px;
  --sliding-column-width: 600px;

  @media (min-width: 1024px) {
    --col-width: 280px;
  }
  @media (min-width: 1280px) {
    --col-width: 320px;
  }
  @media (min-width: 1440px) {
    --col-width: 450px;
  }
}

.layout__content {
  width: calc(100% - var(--col-width));
  min-width: var(--sliding-column-width);
  margin-left: var(--col-width);
  padding: 20px 0;
  overflow-y: scroll;
  height: calc(100vh - var(--header-height));
}

.layout__inner {
  max-width: 948px;
  padding: 0 24px;
  margin: 0 auto;
}

.layout__sidebar {
  position: fixed;
  z-index: 1;
  overflow: auto;
  top: var(--header-height);
  padding: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  width: var(--col-width);
  background: white;

  .ant-menu-vertical {
    border-right: 0;
  }
}

.layout__row {
  flex-flow: row;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 -24px;

  .layout__content {
    position: sticky;
    left: 0px;
    right: calc(-1 * var(--sliding-column-width));
    z-index: 2;
    background: white;
    box-shadow: 0 0 3px rgba(black, 0.3);
  }
}

.layout__right-sidebar {
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  box-shadow: 0 0 3px rgba(black, 0.3);
  background: white;
  overflow-y: scroll;
  width: var(--sliding-column-width);
  min-width: var(--sliding-column-width);
  z-index: var(--right-sidebar-zindex);
  padding: 24px;
  position: sticky;
  left: 40px;
  right: calc(-1 * var(--sliding-column-width));

  @media (min-width: 2100px) {
    right: -800px;
    width: 800px;
    min-width: 800px;
  }
}

.risk-heatmap {
}
.risk-dot {
  width: 15px;
  height: 15px;
  display: inline-block;
  overflow: hidden;
  background: black;
  margin: 3px;
  vertical-align: top;
  border-radius: 10px;
  cursor: default;
  background: rgba(black, 0.1);
  color: transparent;

  a {
    /* display: block; */
    color: transparent;
    /* width: 15px; */
    /* height: 15px; */
  }

  &:hover {
    border: 1px solid red;
  }
}

.risk--current {
  background: rgba(black, 0.5);
}

.risk--residual {
  background: black;
}

.coverage {
  .ant-descriptions-view,
  .ant-descriptions-row {
    border: 0;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    background: none;
    border: 0;
    padding: 5px 0 !important;
    vertical-align: top;
  }
  .ant-descriptions-item-label {
    font-weight: bold;
  }
  .ant-descriptions-item-content {
    padding-left: 10px !important;
  }
}
.coverage__title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: -12px;
  margin-bottom: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: -16px;
  padding-right: 16px;
  overflow: hidden;
}

.coverage__coverage {
  margin-left: auto;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  padding-left: 16px;
  width: 120px;
  text-align: center;
  text-transform: uppercase;
  flex-shrink: 0;
  margin-top: -12px;
  margin-bottom: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: -16px;
  padding-right: 16px;
}

.coverage__coverage--full {
  background-color: #44af6e;
  color: white;
}
.coverage__coverage--none {
  background-color: red;
  color: white;
}
.coverage__coverage--partial {
  background-color: #b58900;
  color: white;
}

.coverage__tag {
  border: 1px solid rgba(black, 0.1);
}

.coverage__tag--black-text {
  color: black;
}

.section__edit {
  .coverage__tag {
    margin-top: 5px;
  }
}

.coverage__requirement {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 15px;
  min-width: 0;
}
